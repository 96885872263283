<template>
  <div class="w-100 position-relative">
    <CCard class="">
      <div class="modal-body modal-container">
        <div class="pdf-wrapper">
          <div class="pdf-page fs-16">
            <div id="pdf-2" class="pdf-content ml-3 mr-3">
              <div class="text-center fs-16">
                <strong class="bold">Mẫu C.II.2 <br>
                  Biểu tổng hợp dự kiến chương trình xúc tiến đầu tư hàng năm</strong>
                <p class="font-italic">(Áp dụng đối với chương trình xúc tiến đầu tư của Bộ/Ủy ban nhân dân cấp tỉnh
                  theo quy định tại điểm a, khoản 2, Điều 93 Nghị định số 31/2021/NĐ-CP)</p>
                <hr>
              </div>
              <div style="margin-left: 10em">
                <p class="font-weight-bold text-uppercase">{{ item.tenBo_UBNDTinh }}</p>
                <hr width="150px" style="border: 1px double; margin-left: 1em">
              </div>
              <div class="row">
                <div class="col"></div>
                <div class="col"></div>
                <div class="col float-right">
                  <p class="font-weight font-italic">
                    {{ item.diaDiem }}, ngày {{ getDay(item.ngayVanBan) }} tháng {{ getMonth(item.ngayVanBan) }} năm
                    {{ getYear(item.ngayVanBan) }} </p>
                </div>
              </div>
              <div class="text-center">
                <span class="text-uppercase font-weight-bold">BIỂU TỔNG HỢP DỰ KIẾN CHƯƠNG TRÌNH XÚC TIẾN ĐẦU TƯ NĂM {{
                    getYear(item.ngayVanBan)
                  }}</span> <br>
                <span class="font-italic">(Kèm theo Văn bản dự kiến Chương trình xúc tiến đầu tư năm {{
                    getYear(item.ngayVanBan)
                  }} của {{ item.tenBo_UBNDTinh }} tại công văn số….ngày.., tháng… năm…)</span>
              </div>
              <div class="row">
                <div class="col"></div>
                <div class="col"></div>
                <div class="col float-right">
                  <p class="font-weight mb-3">Đơn vị: chương trình XTĐT</p>
                </div>
              </div>
              <div class="row">
                <table class="col-12 table table-bordered table-striped">
                  <thead>
                  <tr>
                    <th class="text-center align-middle fs-12" width="25px" rowspan="3">TT</th>
                    <th class="p-1 text-center fs-13" width="100px" rowspan="3">Tên hoạt động xúc tiến đầu tư</th>
                    <th class="p-1 text-center fs-13" rowspan="3">Loại hoạt động xúc tiến đầu tư</th>
                    <th class="p-1 text-center fs-13" rowspan="3">Thời gian tổ chức</th>
                    <th class="p-1 text-center fs-13" rowspan="3">Đơn vị chủ trì thực hiện</th>
                    <th class="p-1 text-center fs-13" colspan="2">Địa điểm tổ chức</th>
                    <th class="p-1 text-center fs-13" rowspan="3">Mục đích/Nội dung hoạt động</th>
                    <!--                    <th class="p-1 text-center fs-13" rowspan="3">Ngành/Lĩnh vực kêu gọi đầu tư (ngành ISIC cấp 2)</th>-->
                    <th class="p-1 text-center fs-13" rowspan="3">Địa bàn/Tỉnh/Vùng kêu gọi đầu tư</th>
                    <!--                    <th class="p-1 text-center fs-13" rowspan="3">Căn cứ triển khai hoạt động</th>-->
                    <!--                    <th class="p-1 text-center fs-13" colspan="4">Đơn vị phối hợp</th>-->
                    <th class="p-1 text-center fs-13" colspan="2">Đơn vị phối hợp</th>
                    <th class="p-1 text-center fs-13" colspan="2">Kinh phí</th>
                  </tr>
                  <tr>
                    <th class="p-1 text-center fs-13" rowspan="2">Trong nước</th>
                    <th class="p-1 text-center fs-13" rowspan="2">Nước ngoài</th>
                    <th class="p-1 text-center fs-13" rowspan="2">Tên đơn vị</th>
                    <th class="p-1 text-center fs-13" rowspan="2">Quốc tịch/Tỉnh, thành phố</th>
                    <!--                    <th class="p-1 text-center fs-13" rowspan="2">Tên tổ chức/Cơ quan trong nước</th>-->
                    <!--                    <th class="p-1 text-center fs-13" rowspan="2">Tên tổ chức/Cơ quan nước ngoài</th>-->
                    <!--                    <th class="p-1 text-center fs-13" rowspan="2">Doanh nghiệp trong nước</th>-->
                    <!--                    <th class="p-1 text-center fs-13" rowspan="2">Doanh nghiệp nước ngoài</th>-->
                    <th class="p-1 text-center fs-13" rowspan="2">Ngân sách địa phương</th>
                    <th class="p-1 text-center fs-13 " rowspan="2">Khác (xã hội hóa)</th>
                    <!--                    <th class="p-1 text-center fs-13 " rowspan="2">Tổng kinh phí</th>-->
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td class="text-center p-1 fs-13 align-middle">(1)</td>
                    <td class="text-center p-1 fs-13 align-middle">(2)</td>
                    <td class="text-center p-1 fs-13 align-middle">(3)</td>
                    <td class="text-center p-1 fs-13 align-middle">(4)</td>
                    <td class="text-center p-1 fs-13 align-middle">(5)</td>
                    <td class="text-center p-1 fs-13 align-middle">(6)</td>
                    <td class="text-center p-1 fs-13 align-middle">(7)</td>
                    <td class="text-center p-1 fs-13 align-middle">(8)</td>
                    <td class="text-center p-1 fs-13 align-middle">(9)</td>
                    <!--                      <td class="text-center p-1 fs-13 align-middle"></td>-->
                    <!--                      <td class="text-center p-1 fs-13 align-middle"></td>-->
                    <td class="text-center p-1 fs-13 align-middle">(10)</td>
                    <td class="text-center p-1 fs-13 align-middle">(11)</td>
                    <td class="text-center p-1 fs-13 align-middle">(12)</td>
                    <td class="text-center p-1 fs-13 align-middle">(13)</td>
                  </tr>
                  <template v-if="chiTietDeXuatCTXTDT.length">
                    <tr v-for="(it, indx) in chiTietDeXuatCTXTDT" v-bind:key="it.id">
                      <td class="text-center p-1 fs-13 align-middle" width="25px" :style="getLineThrough(it.trangThai)">
                        {{ indx + 1 }}
                        <span v-if="it.trangThai === 2">(*)</span>
                        <span v-if="it.trangThai === 3">(**)</span>
                      </td>
                      <td class="text-center p-1 fs-13 align-middle " :style="getLineThrough(it.trangThai)">{{ it.tenHoatDong }}</td>
                      <td class="text-center p-1 fs-13 align-middle " :style="getLineThrough(it.trangThai)">
                        {{ displayLabel2(optionsLoaiHoatDong, it.loaiHoatDongId) }}
                        <!--                        <select disabled class="form-control" v-model="it.loaiHoatDongId"-->
                        <!--                                style="background-color: white">-->
                        <!--                          <option :value="itemz.value" v-for="itemz in optionsLoaiHoatDong"-->
                        <!--                                  v-bind:key="itemz.id">{{ itemz.label }}-->
                        <!--                          </option>-->
                        <!--                        </select>-->
                      </td>
                      <td class="text-center p-1 fs-13 align-middle " :style="getLineThrough(it.trangThai)">
                        {{ it.duKienThoiGianToChuc | formatDate }}
                        <!--                        <v-date-picker class="" v-model="it.duKienThoiGianToChuc" locale="vi">-->
                        <!--                          <template v-slot="{ inputValue, inputEvents }">-->
                        <!--                            <input-->
                        <!--                              disabled-->
                        <!--                              class="form-control"-->
                        <!--                              :value="inputValue"-->
                        <!--                              v-on="inputEvents"-->
                        <!--                              style="background-color: white"-->
                        <!--                            />-->
                        <!--                          </template>-->
                        <!--                        </v-date-picker>-->
                      </td>
                      <td class="text-center p-1 fs-13 align-middle " :style="getLineThrough(it.trangThai)">
                        {{ it.donViChuTriThucHien }}
                      </td>
                      <td class="text-center p-1 fs-13 align-middle " :style="getLineThrough(it.trangThai)">
                        {{ displayLabel2(optionsTinhThanh, it.diaDiem_TrongNuocId) }}
                        <!--                        <select disabled class="form-control" v-model="it.diaDiem_TrongNuocId"-->
                        <!--                                style="background-color: white">-->
                        <!--                          <option :value="itemz.value" v-for="itemz in optionsTinhThanh"-->
                        <!--                                  v-bind:key="itemz.id">{{ itemz.label }}-->
                        <!--                          </option>-->
                        <!--                        </select>-->
                      </td>
                      <td class="text-center p-1 fs-13 align-middle " :style="getLineThrough(it.trangThai)">
                        {{ displayLabel2(optionsQuocGia, it.diaDiem_NuocNgoaiId) }}
                        <!--                        <select disabled class="form-control" v-model="it.diaDiem_NuocNgoaiId"-->
                        <!--                                style="background-color: white">-->
                        <!--                          <option :value="itemz.value" v-for="itemz in optionsQuocGia" v-bind:key="itemz.id">-->
                        <!--                            {{ itemz.label }}-->
                        <!--                          </option>-->
                        <!--                        </select>-->
                      </td>
                      <td class="text-center p-1 fs-13 align-middle " :style="getLineThrough(it.trangThai)">{{ it.mucDich_NoiDungHoatDong }}</td>
                      <!--                      <td class="text-center p-1 fs-13 align-middle ">{{ displayLabel(optionsNganhLinhVuc,it.nganhLinhVucKeuGoiDauTuId)}}-->
                      <!--&lt;!&ndash;                        <select disabled class="form-control" v-model="it.nganhLinhVucKeuGoiDauTuId"&ndash;&gt;-->
                      <!--&lt;!&ndash;                                style="background-color: white">&ndash;&gt;-->
                      <!--&lt;!&ndash;                          <option :value="itemz.value" v-for="itemz in optionsNganhLinhVuc"&ndash;&gt;-->
                      <!--&lt;!&ndash;                                  v-bind:key="itemz.id">{{ itemz.label }}&ndash;&gt;-->
                      <!--&lt;!&ndash;                          </option>&ndash;&gt;-->
                      <!--&lt;!&ndash;                        </select>&ndash;&gt;-->
                      <!--                      </td>-->
                      <td class="text-center p-1 fs-13 align-middle " :style="getLineThrough(it.trangThai)">
                        {{ displayLabel(optionsDiaBanKeuGoiDauTu, it.diaBanKeuGoiDauTu) }}
                        <!--                                  <select disabled class="form-control" v-model="it.diaBanKeuGoiDauTu" style="background-color: white">-->
                        <!--                                    <option :value="itemz.value" v-for="itemz in optionsDiaBanKeuGoiDauTu"-->
                        <!--                                            v-bind:key="itemz.id">{{ itemz.label }}-->
                        <!--                                    </option>-->
                        <!--                                  </select>-->
                      </td>
                      <!--                      <td class="text-center p-1 fs-13 align-middle ">{{ it.canCuTrienKhai }}</td>-->
                      <td class="text-center p-1 fs-13 align-middle " :style="getLineThrough(it.trangThai)">
                        {{ it.toChucCoQuan_TrongNuoc }} <br>
                        {{ it.toChucCoQuan_NuocNgoai }} <br>
                        {{ it.doanhNghiep_TrongNuoc }} <br>
                        {{ it.doanhNghiep_NuocNgoai }} <br>
                      </td>
                      <td class="text-center p-1 fs-13 align-middle " :style="getLineThrough(it.trangThai)"></td>
                      <!--                      <td class="text-center p-1 fs-13 align-middle ">{{ it.toChucCoQuan_NuocNgoai }}</td>-->
                      <!--                      <td class="text-center p-1 fs-13 align-middle ">{{ it.doanhNghiep_TrongNuoc }}</td>-->
                      <!--                      <td class="text-center p-1 fs-13 align-middle ">{{ it.doanhNghiep_NuocNgoai }}</td>-->

                      <!--                                <td class="text-center p-1 fs-13 align-middle w-120">-->
                      <!--                                  <select disabled class="form-control" v-model="it.nguonKinhPhi" style="background-color: white">-->
                      <!--                                    <option :value="itemz.value" v-for="itemz in optionsNguoiKinhPhi"-->
                      <!--                                            v-bind:key="itemz.id">{{ itemz.label }}-->
                      <!--                                    </option>-->
                      <!--                                  </select>-->
                      <!--                                </td>-->
                      <td class="text-center p-1 fs-13 align-middle " :style="getLineThrough(it.trangThai)">{{
                          it.kinhPhi_NganSachDiaPhuong | formatNumber
                        }}
                      </td>
                      <td class="text-center p-1 fs-13 align-middle " :style="getLineThrough(it.trangThai)">{{ it.kinhPhi_Khac | formatNumber }}</td>
                      <!--                      <td class="text-center p-1 fs-13 align-middle ">-->
                      <!--                        {{ Number(it.kinhPhi_NganSachDiaPhuong) + Number(it.kinhPhi_Khac) | formatNumber }}-->
                      <!--                      </td>-->
                      <!--                      <td class="text-center p-1 fs-13 align-middle ">-->
                      <!--                        <span v-if="it.trangThai === 1">Ban đầu</span>-->
                      <!--                        <span v-if="it.trangThai === 2">Điều chỉnh</span>-->
                      <!--                        <span v-if="it.trangThai === 3">Bổ sung</span>-->
                      <!--                        <span v-if="it.trangThai === 4">Hủy bỏ</span>-->
                      <!--                        <span v-if="!it.trangThai">Mới</span>-->
                      <!--                      </td>-->
                    </tr>
                    <tr>
                      <td class="text-center p-1 fs-13 align-middle"></td>
                      <td class="text-center p-1 fs-13 align-middle">Tổng số</td>
                      <td class="text-center p-1 fs-13 align-middle"></td>
                      <td class="text-center p-1 fs-13 align-middle"></td>
                      <td class="text-center p-1 fs-13 align-middle"></td>
                      <td class="text-center p-1 fs-13 align-middle"></td>
                      <td class="text-center p-1 fs-13 align-middle"></td>
                      <td class="text-center p-1 fs-13 align-middle"></td>
                      <td class="text-center p-1 fs-13 align-middle"></td>
                      <!--                      <td class="text-center p-1 fs-13 align-middle"></td>-->
                      <!--                      <td class="text-center p-1 fs-13 align-middle"></td>-->
                      <td class="text-center p-1 fs-13 align-middle"></td>
                      <td class="text-center p-1 fs-13 align-middle"></td>
                      <td class="text-center p-1 fs-13 align-middle">{{ tongTienNganSach | formatNumber }}</td>
                      <td class="text-center p-1 fs-13 align-middle">{{ tongTienTaiTro | formatNumber }}</td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td class="text-center p-1 fs-13 align-middle" colspan="15">Chưa có dữ liệu</td>
                    </tr>
                  </template>
                  </tbody>
                </table>
              </div>
              <div>
                <p class="font-italic" style="text-indent: 3em">Lưu ý: đối với trường hợp điều chỉnh/bổ sung, đánh dấu
                  (*) ở cột số (1) đối với các hoạt động đã điều chỉnh; dấu (**) đối với các hoạt động bổ sung; và gạch
                  ngang toàn bộ dòng đối với các hoạt động đề nghị hủy bỏ <span style="text-decoration-line: line-through">(gạch ngang)</span>.</p>
              </div>
              <div class="row mt-3">
                <div class="col ml-3">
                </div>
                <div class="col">
                  <div class="float-right mr-5" style="text-align: center">
                    <span class="font-weight-bold text-uppercase">Người lập biểu</span> <br>
                    <span class="font-italic text-align">(Ghi rõ họ tên, đơn vị, ký, điện thoại, email)</span><br><br><br>
                    <br>
                    <br>
                  </div>
                </div>
              </div>
            </div>
            <div class="print-btn close-button">
              <button class="print-button" @click="print">
                <span class="print-icon"></span></button>
            </div>
          </div>
        </div>
      </div>
    </CCard>
  </div>
</template>

<script>
import { displayLabel, displayLabel2 } from '@/shared/appConstants'
import { tinhThanhService } from '@/services/tinh-thanh.service'
import { quocGiaService } from '@/services/quoc-gia.service'
import { nganhLinhVucService } from '@/services/nganh-linh-vuc.service'
import moment from 'moment'
import appConfig from '@/shared/appConfig'
import { loaiHoatDongService } from '@/services/loai-hoat-dong.service'
import { vungKinhTeService } from '@/services/vung-kinh-te.service'
import { enums } from '@/shared/enums'

export default {
  name: 'DeXuatCTXTDTPhuLucPrint',
  data () {
    return {
      Attachments: [],
      type: 'DeXuatCTXTDTPhuLuc',
      isDeleteConfirmationModalOpen: false,
      optionsNganhLinhVuc: [],
      optionsTinhThanh: [],
      optionsQuocGia: [],
      optionsLoaiHoatDong: [],
      optionsNguoiKinhPhi: [],
      optionsDiaBanKeuGoiDauTu: [],
      item: {},
      chiTietDeXuatCTXTDT: [],
      tongTienNganSach: 0,
      tongTienTaiTro: 0,
      tongTien: 0
    }
  },
  computed: {},
  methods: {
    displayLabel,
    displayLabel2,
    getDownloadURL (url) {
      return url && (url.indexOf('http://') > -1 || url.indexOf('https://') > -1) ? url : appConfig.BASE_HOST + url
    },
    getDate: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('DD/MM/YYYY') : '',
    getDay: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('DD') : '',
    getMonth: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('MM') : '',
    getYear: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('YYYY') : '',
    back () {
      this.$router.push({ path: '/de-xuat-ct-xtdt' })
    },
    getLocalStorage () {
      const jsonData = localStorage.getItem('de_xuat_ct_xtdt_phu_luc')
      const data = JSON.parse(jsonData)
      if (data) {
        this.item = data.item
        this.chiTietDeXuatCTXTDT = data.chiTietDeXuatCTXTDT
        this.countTongTien()
      }
      console.log(data)
    },
    print () {
      window.print()
    },
    countTongTien () {
      if (this.chiTietDeXuatCTXTDT.length) {
        let tong = 0
        let tongNganSach = 0
        let tongKhac = 0
        for (const i in this.chiTietDeXuatCTXTDT) {
          tongNganSach += this.chiTietDeXuatCTXTDT[i].kinhPhi_NganSachDiaPhuong
          tongKhac += this.chiTietDeXuatCTXTDT[i].kinhPhi_Khac
          tong += this.chiTietDeXuatCTXTDT[i].kinhPhi_NganSachDiaPhuong + this.chiTietDeXuatCTXTDT[i].kinhPhi_Khac
        }
        this.tongTienNganSach = tongNganSach
        this.tongTienTaiTro = tongKhac
        this.tongTien = tong
      }
    },
    getLineThrough (trangThai) {
      if (trangThai === 4) return 'text-decoration-line: line-through'
      else return ''
    }
  },
  async created () {
    if (this.$route.params.id) {
      await this.getItem(this.$route.params.id)
    } else {
      await this.getLocalStorage()
    }
    if (this.item.dinhKem) this.Attachments = JSON.parse(this.item.dinhKem)
    this.optionsTinhThanh = await tinhThanhService.getAll()
    this.optionsQuocGia = await quocGiaService.getAll()
    this.optionsNganhLinhVuc = await nganhLinhVucService.getAll()
    this.optionsLoaiHoatDong = await loaiHoatDongService.getAll()
    this.optionsNguoiKinhPhi = enums.nguonKinhPhi
    this.optionsDiaBanKeuGoiDauTu = await vungKinhTeService.getAll()
  }
}
</script>

<style scoped>

</style>
